import {
  PieChartOutlined,
  UserOutlined,
  HomeOutlined,
  ReadOutlined,
  NotificationOutlined,
  TrophyOutlined,
  ToTopOutlined,
  DesktopOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";

import { Button, Image, Layout, Menu, Space, theme } from "antd";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/store";
import {
  BadgeIndianRupee,
  Contact,
  Film,
  Focus,
  ImageDown,
  ImagePlus,
  LayoutPanelLeft,
  Receipt,
  ShoppingBasket,
  SquareStack,
  UploadIcon,
  Video,
  Videotape,
} from "lucide-react";
const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("Home", "/", <LayoutPanelLeft size={16} />),
  getItem("Users", "/users", <Contact size={16} />),
  getItem("Category", "/category", <SquareStack size={16} />),
  getItem("Photo", "/photos", <Focus size={16} />),
  getItem("Video", "/video", <Video size={16} />),
  getItem(
    "Todays Transactions",
    "/todays-transaction",
    <BadgeIndianRupee size={16} />
  ),
  getItem(
    "Photo Purchase Records",
    "/photos-transaction/purchase",
    <ImagePlus size={16} />
  ),
  getItem(
    "Photo Sold Records",
    "/photos-transaction/sold",
    <ImageDown size={16} />
  ),
  getItem(
    "Video Purchase Records",
    "/video-transaction/purchase",
    <Videotape size={16} />
  ),
  getItem("Video Sold Records", "/video-transaction/sold", <Film size={16} />),
  getItem("Admin", "/admin", <Film size={16} />),
  getItem("Notification", "/add-notification", <DesktopOutlined />),
  getItem("Upload", "/upload", <UploadIcon size={16} />),
  // getItem("ADS", "/ads", <NotificationOutlined />),
  // getItem("Spinner", "/spinner", <TrophyOutlined />),
  // getItem("Gifts", "/gifts", <TrophyOutlined />),
  // getItem("Admins", "/admin", <UserOutlined />),
  //   getItem("User", "sub1", <UserOutlined />, [
  //     getItem("Tom", "3"),
  //     getItem("Bill", "4"),
  //     getItem("Alex", "5"),
  //   ]),
  //   getItem("Team", "sub2", <TeamOutlined />, [
  //     getItem("Team 1", "6"),
  //     getItem("Team 2", "8"),
  //   ]),
  // getItem("Upgrade Request", "/upgrade-request", <ToTopOutlined />),
];

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const router = useNavigate();
  const pathname = useLocation().pathname;
  const user = useAuth((state) => state.user);
  const setUser = useAuth((state) => state.setUser);
  const [currentKey, setCurrentKey] = React.useState(
    "/" + pathname.split("/")[1]
  );
  React.useEffect(() => {
    if (!user) {
      router("/login");
    }
  }, [user]);

  React.useEffect(() => {
    setCurrentKey();
  }, [pathname]);
  console.log(user);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Image
          src="/logo.png"
          preview={false}
          height={40}
          style={{
            marginLeft: 10,
            borderRadius: 5,
            backgroundColor: "white",
          }}
        />

        <Menu
          theme="dark"
          selectedKeys={pathname === "/" ? ["/"] : [pathname]}
          mode="inline"
          items={items}
          onClick={({ key }) => {
            router(key);
          }}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: "#001529" }}>
          <Space style={{ float: "right", marginRight: 20 }}>
            <Link
              to="https://play.google.com/store/apps/details?id=com.swiss.stocks"
              target="_blank"
              type="primary"
            >
              <Button type="primary">App</Button>
            </Link>
            <Link
              type="primary"
              to="https://www.swissstocks.in/"
              target="_blank"
            >
              <Button type="primary">Website</Button>
            </Link>
            <Link
              type="primary"
              to="https://console.firebase.google.com/project/swiss-stocks-5e6b9/analytics/app/android:com.swiss.stocks/overview"
              target="_blank"
            >
              <Button type="primary">Analytics</Button>
            </Link>
            <Button
              type="primary"
              danger
              onClick={() => {
                setUser(null);
              }}
            >
              Logout
            </Button>
          </Space>
        </Header>
        <Content>{children}</Content>
        {/* <Footer style={{ textAlign: "center" }}>Created by</Footer> */}
      </Layout>
    </Layout>
  );
};

export default MainLayout;
