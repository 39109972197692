import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Statistic,
  Switch,
  Typography,
  message,
} from "antd";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  UserOutlined,
  ApartmentOutlined,
  ClusterOutlined,
  VideoCameraOutlined,
  ContainerOutlined,
  MoneyCollectOutlined,
  BookOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import {
  getDash,
  getSystemValues,
  putsystemval,
  updateSytstemValues,
} from "../utils/api";
import { useQuery, useMutation } from "@tanstack/react-query";
import { StarOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/store";
import { Link } from "react-router-dom";

const Home = () => {
  const user = useAuth((state) => state.user);
  const [width, setWidth] = React.useState(window.innerWidth);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["systemValues"],
    queryFn: () => getSystemValues(),
  });
  const { data: dash, isLoading: isLoading3 } = useQuery({
    queryKey: ["dashboard"],
    queryFn: () => getDash(),
  });
  console.log(data);
  const { mutate, isLoading: isLoading2 } = useMutation(
    async (values) => await putsystemval(values),
    {
      onSuccess: () => {
        message.success(`Updated`);
      },
    }
  );

  const colors = ["#C70039", "#FF5733", "#FFC300", "#219C90"];

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => window.removeEventListener("resize", () => {});
  }, []);

  return (
    <>
      <Space
        direction="vertical"
        size="large"
        style={{ width: "100%", padding: 20 }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
          >
            Dashboard
          </Typography>
          {user?.adminValues && <AddData refetch={refetch} update={data} />}
        </div>
        <Row gutter={[16, 16]}>
          {[
            {
              name: "Users",
              value: dash?.users,
              icon: <UserOutlined />,
              link: "/users",
            },

            {
              name: "Categories",
              value: dash?.categories,
              icon: <ApartmentOutlined />,
              link: "/category",
            },
            {
              name: "Photos",
              value: dash?.photos,
              icon: <PictureOutlined />,
              link: "/photos",
            },
            {
              name: "Videos",
              value: dash?.videos,
              icon: <ContainerOutlined />,
              link: "/video",
            },
          ]?.map((item, index) => (
            <Col className="gutter-row" span={8} xs={20} sm={8} key={index}>
              <Link to={item.link}>
                <Card>
                  <Statistic
                    title={item.name}
                    value={item.value}
                    //title color

                    prefix={item.icon}
                  />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Space>
      <Space
        direction="vertical"
        style={{
          width: "100%",
          padding: 20,
          backgroundColor: "white",
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
          User Graph
        </Typography>
        <BarChart
          className="chart"
          width={
            width > 600
              ? width > 800
                ? width > 1000
                  ? 800
                  : 600
                : 400
              : width - 100
          }
          height={250}
          data={
            dash?.userGraph?.map((item) => {
              return {
                date: `${new Date(
                  item?.dateStart
                ).toLocaleDateString()} - ${new Date(
                  item?.dateEnd
                ).toLocaleDateString()}`,
                count: item?.count,
                dateStart: item?.dateStart,
                dateEnd: item?.dateEnd,
                users: item?.users,
              };
            }) || []
          }
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            label={{
              value: "Number of Users",
              angle: -90,
              position: "insideLeft",
              offset: 10,
            }}
          />
          <Bar dataKey="count" fill="#8884d8" />
          <Legend />
          <Tooltip />
        </BarChart>
      </Space>
    </>
  );
};

export default Home;

const AddData = ({
  refetch,

  update,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const type = Form.useWatch("rewardType", form);
  const cashfree = Form.useWatch("isCashFreeEnable", form);
  const razorpay = Form.useWatch("isRazorpayEnable", form);
  const { mutate, isLoading } = useMutation(
    async (values) =>
      update
        ? await updateSytstemValues(values)
        : await updateSytstemValues(values),
    {
      onSuccess: () => {
        messageApi.success(`Added Admin Values`);
        //reset form
        !update && form.resetFields();
        refetch();
        setVisible(false);
      },
      onError: (err) => {
        messageApi.error(err?.response?.data?.message);
      },
    }
  );

  return (
    <div>
      {contextHolder}
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        style={{
          width: "100%",
        }}
      >
        {update ? "Admin Values" : "Admin Values"}
      </Button>
      <Modal
        title={update ? "Admin Values" : "Admin"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 10 }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update && {
              upiCharge: update.upiCharge,
              photoSellingMaxDays: update.photoSellingMaxDays,
              transactionCharge: update.transactionCharge,
              dob: update.dob,
              wallet: update.wallet,
              videoUrl: update.videoUrl,
              razorpayKey: update.razorpayKey,
              razorpaySecret: update.razorpaySecret,
              cashFreeClientId: update.cashFreeClientId,
              cashfreeSecret: update.cashfreeSecret,
              isCashFreeEnable: update.isCashFreeEnable,
              isRazorpayEnable:
                update.isRazorpayEnable === "true" ? true : false,
            }
          }
          onFinish={(values) => {
            mutate(values);
          }}
        >
          <Form.Item
            label="UPI Charge (in ₹)"
            name="upiCharge"
            required
            rules={[
              {
                required: true,
                message: "Please input your Upi Charge!",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Photo Selling Max Days"
            name="photoSellingMaxDays"
            required
            rules={[
              {
                required: true,
                message: "Please input your Photo Selling Max Days!",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Transaction Charge (in %)"
            name="transactionCharge"
            required
            rules={[
              {
                required: true,
                message: "Please input your Transaction Charge!",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Video Url"
            name="videoUrl"
            required
            rules={[
              {
                required: true,
                message: "Please input ",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="CashFree Enable"
            name="isCashFreeEnable"
            required
            rules={[
              {
                required: true,
                message: "Please input ",
              },
            ]}
          >
            <Switch checked={cashfree} />
          </Form.Item>
          <Form.Item
            label="Razorpay Enable"
            name="isRazorpayEnabled"
            required
            rules={[
              {
                required: true,
                message: "Please input ",
              },
            ]}
          >
            <Switch checked={razorpay} />
          </Form.Item>
          <Form.Item
            label="CashFree Client Id"
            name="cashFreeClientId"
            required
            rules={[
              {
                required: true,
                message: "Please input ",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Cashfree Secret"
            name="cashfreeSecret"
            required
            rules={[
              {
                required: true,
                message: "Please input ",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Razorpay Key"
            name="razorpayKey"
            required
            rules={[
              {
                required: true,
                message: "Please input ",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
